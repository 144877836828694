import React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"
import Header from "src/components/header"
import Footer from "src/components/footer"
import Mainsection from "src/components/section/main"
import Subsection from "src/components/section/sub"
import Button from "src/components/LinkedButton"
import Spacer from "src/components/spacer"
// import Styles from "./contact.module.scss"

const ContactBox = ({ header, icon, buttonTitle, path, children }) => (
  <div>
    <div className="box">
      <Subsection title={header} icon={icon}>
        <div className="content">{children}</div>
        <div className="has-text-right"><Button to={path} title={buttonTitle} /></div>
      </Subsection>
    </div>
    <Spacer isHalf />
  </div>
)

const Inquery = () => (
  <ContactBox header="お問い合わせ" icon="fas fa-envelope" buttonTitle="問い合わせる" path="/contact/inquery">
    <p>natadeCOCO全般に関するご質問・ご相談をフォームにて受け付けております。</p>
    <p className="is-size-7">※メールアドレスが必要です。</p>
  </ContactBox>
)

const twitter ="https://twitter.com/nCOCOSupport"
const Twitter = () => (
  <ContactBox header="公式Twitter" icon="fab fa-twitter" buttonTitle="ツイートを見る" path={twitter}>
    <p>Twitterアカウント <strong>@nCOCOSupport</strong> では、natadeCOCOに関する情報をリアルタイムに発信しております。<br />
       ダイレクトメッセージによるご質問・ご相談も受け付けております！</p>
    <p className="is-size-7">※TwitterアカウントおよびTwitterへのログインが必要です</p>
  </ContactBox>
)

const slack = "https://join.slack.com/t/natadecoco/shared_invite/enQtOTA3MDk5NDQ4MTk3LTE2OTIwZWM5MDNlNmU2NmYyZjcwMWVjYWUxOTFhNzM2MjU2NTdkYTY3ZTBhMzIwYmIzOGY5N2VlNzhlOWE1MDU"
const Slack = () => (
  <ContactBox header="コミュニティ" icon="fab fa-slack" buttonTitle="コミュニティに参加する" path={slack}>
    <p>SlackのnatadeCOCOワークスペースでは、ユーザー同士のコラボレーションを促進しています。<br />
       リテーラー・クリエーター・エンジニアなど各分野のスペシャリストと一緒にさまざまなアイデアを実現しましょう！</p>
    <p className="is-size-7">※SlackアカウントおよびSlackへのログインが必要です</p>
  </ContactBox>
)

/*
const Connpass = () => (
  <ContactBox header="開催イベント" icon="far fa-calendar-alt" buttonTitle="イベントに参加する" path="https://natadecoco.connpass.com/">
    <p>connpassのnatadeCOCOグループでは、<strong>ワークショップ・ハンズオン・ハッカソン</strong>を中心としたイベントを開催いたします。<br />
       これからスキルを身に付けたいとお考えの方も是非お気軽にご参加ください！</p>
    <p className="is-size-7">※connpassアカウントおよびconnpassへのログインが必要です</p>
  </ContactBox>
)
*/

const IndexPage = ({ pageContext }) => (
  <Layout>
    <Seo />
    <Header />
    <main>
      <Mainsection title="お問い合わせ窓口" subtitle="natadeCOCOに関するご質問・ご相談などにつきましては、以下の窓口をご用意しております。"/>
      <section className="section" style={{ paddingTop: "0"}}>
        <div className="container">
          <Inquery />
          <Twitter />
          <Slack />
          {/*
          <Connpass />
          */}
        </div>
      </section>
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
